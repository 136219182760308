.About {
  &--transition-out {
    opacity: 0 !important;
  }

  &__image-container {
    top: 3%;
    right: 30%;

    @include media('md-up') {
      right: 12%;
    }
  }

  &__image-clip {
    transition: transform 10s ease-in-out;
  }

}
