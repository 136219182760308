.TextField {
  &--primary {
    input, textarea {
      @extend .radius-clickable-rounded;
      height: $clickable-height-desktop;
      border: $border;
      padding-left: 1rem;
      padding-right: 1rem;
      background: color('white');
      box-shadow: 4px 4px 4px color('shadow');
      transition: $transition-short;

      &:focus {
        outline: 0;
        border-color: color('text');
        box-shadow: 2px 2px 2px color('shadow');
      }

      &:hover {
        border-color: color('primary');
      }
    }
  }
}
