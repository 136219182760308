.text-card-title {
  font-weight: bold;
  font-size: .875rem;
  line-height: 1.25rem;

  @include media('md-up') {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.text-lg {
  font-size: 2.45rem;
  line-height: 3.25rem;

  @include media('md-up') {
    font-size: 4.45rem;
    line-height: 4.85rem;
  }
}

.text-sm {
  font-size: .575rem;
  line-height: .925rem;

  @include media('md-up') {
    font-size: .875rem;
    line-height: 1.125rem;
  }
}

.text-shadow {
  text-shadow: 2px 2px 2px color('shadow');
}

body {
  color: color('text');
  font-family: $serif;

  * {
    font-family: $serif;
  }
}

a {
  color: color('text');
}
