$colors: (
  'white': #ffffff,
  'black': #272f39,
  'gray-light': #aaa,

  'primary': var(--primary),
  'secondary': var(--secondary),
  'tertiary': var(--tertiary),
  'shadow': var(--shadow),
  'text': var(--text),
  'transparent': transparent
);
