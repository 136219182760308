@import 'settings';
@import '~@sanctucompu/basement/src/index';
@import 'type';
@import 'variables';
@import 'components';
@import 'utils';

.theme-1 {
  // good
  --primary: #0024cc;
  --secondary: #b5ffc2;
  --shadow: #b5d1cc;
  --text: #06004f;
}

.theme-2 {
  // good
  --primary: #ff3319;
  --secondary: #fff59e;
  --shadow: #ffb852;
  --text: #5c2c45;
}

.theme-3 {
  // good
  --primary: #ff616b;
  --secondary: #ffcfc4;
  --shadow: #b5d1cc;
  --text: #008aa1;
}

.theme-4 {
  // good
  --primary: #9161f2;
  --secondary: #b5d1cc;
  --shadow: #4f8fe6;
  --text: #6b2e63;
}

.theme-5 {
  // ok
  --primary: #4733ff;
  --secondary: #b3d9a3;
  --shadow: #65a98f;
  --text: #324e2a;
}

.theme-6 {
  // good
  --primary: #ff8c00;
  --secondary: #ffe600;
  --shadow: #ffb852;
  --text: #9b5348;
}

.theme-7 {
  // good
  --primary: #ff616b;
  --secondary: #ffcfc4;
  --shadow: #c0b490;
  --text: #324e2a;
}

.theme-8 {
  // bad about
  --primary: #00592e;
  --secondary: #b3e8c2;
  --shadow: #5c8a73;
  --text: #324e2a;
}

body {
  background: var(--secondary);

  .colorized {
    background: var(--primary);
    opacity: .7;

    img {
      mix-blend-mode: luminosity;
      display: block;
    }
  }
}
