.text-decoration-none {
  text-decoration: none;
}

.content-padding {
  padding-top: 4.5rem;
}

.mono-image {
  filter: grayscale(100);
  mix-blend-mode: luminosity;
}

.h25 {
  height: 25%;
}

.shadow-lg {
  box-shadow: 4px 4px 4px color('shadow');
}

.shadow-sm {
  box-shadow: 2px 2px 2px color('shadow');
}
