.Button {
  &--primary,
  &--round {
    @extend .radius-clickable-rounded;
    height: $clickable-height-desktop;
    border: $border;
    padding-left: 1rem;
    padding-right: 1rem;
    background: color('white');
    color: color('text');
    box-shadow: 4px 4px 4px color('shadow');
    transition: $transition-medium;
    font-size: .675rem;
    text-align: center;

    @include media('md-up') {
      font-size: .875rem;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      border-color: color('primary');
      box-shadow: 2px 2px 2px color('shadow');
    }

    &:active {
      border-color: color('text');
      box-shadow: 0 0 0 color('shadow');
      transition: $transition-short;
    }
  }

  &--round {
    height: $clickable-height-desktop;
    width: $clickable-height-desktop;
    text-align: center;
    padding: .2rem 1rem .25rem 1rem;

    @include media('md-up') {
      padding: .2rem 1rem .25rem 1.25rem;
    }
  }
}
