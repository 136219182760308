.Nav {
  animation: fade-in 1.5s ease-in-out forwards;
  opacity: 0;

  // &--about-active {
  //   transform: translateY(-40%) scaleY(0);
  //   transition-delay: .5s;
  // }

  &__status {
    opacity: 0;
    pointer-events: none;
    top: 3.5rem;
    right: 1rem;
    border: color('primary') solid thin;
    box-shadow: 4px 4px 4px color('shadow');

    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__inner {
    transition: padding 2s;
  }

  &__title {
    &__text {
      font-size: 1rem;
      font-family: $serif;
      font-style: italic;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 5px;
      white-space: nowrap;

      @include media('md-up') {
        font-size: 1.25rem;
      }
    }
  }
}

@keyframes fade-in {
  0% { opacity: 0 }
  100% { opacity: 1; }
}
