.Entries {
  &__entry {
    width: 50%;

    @include media('sm-up') {
      width: calc(100% / 3);
    }

    @include media('lg-up') {
      width: 25%;
    }

    @include media('xl-up') {
      width: 20%;
    }

    &__header {
      background: color('white');
      border-bottom: color('primary') solid thin;
    }
    
    &__inner {
      background: color('white');
      border: color('primary') solid thin;
      border-radius: 3px;
      box-shadow: 4px 4px 4px color('shadow');
      transition: box-shadow .3s;
    }

    &:hover {
      .Entries__entry__inner {
        box-shadow: 2px 2px 2px color('shadow');
      }
    }

    &:active {
      .Entries__entry__inner {
        box-shadow: 0 0 0 color('shadow');
      }
    }
  }

  &__image {
    img {
      max-height: 200px;
      max-width: 100%;
      width: auto;
    }
  }
}
