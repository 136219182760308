@font-face {
  font-family: 'Archive';
  src: url('/assets/fonts/archive-regular-pro.eot');
  src: url('/assets/fonts/archive-regular-pro.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/archive-regular-pro.woff2') format('woff2'),
    url('/assets/fonts/archive-regular-pro.woff') format('woff'),
    url('/assets/fonts/archive-regular-pro.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Archive';
  src: url('/assets/fonts/archive-italic-pro.eot');
  src: url('/assets/fonts/archive-italic-pro.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/archive-italic-pro.woff2') format('woff2'),
    url('/assets/fonts/archive-italic-pro.woff') format('woff'),
    url('/assets/fonts/archive-italic-pro.ttf') format('truetype');
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Archive';
  src: url('/assets/fonts/archive-bold-pro.eot');
  src: url('/assets/fonts/archive-bold-pro.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/archive-bold-pro.woff2') format('woff2'),
    url('/assets/fonts/archive-bold-pro.woff') format('woff'),
    url('/assets/fonts/archive-bold-pro.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Archive';
  src: url('/assets/fonts/archive-bold-italic-pro.eot');
  src: url('/assets/fonts/archive-bold-italic-pro.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/archive-bold-italic-pro.woff2') format('woff2'),
    url('/assets/fonts/archive-bold-italic-pro.woff') format('woff'),
    url('/assets/fonts/archive-bold-italic-pro.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Archive Mono';
  src: url('/assets/fonts/archive-mono-pro.eot');
  src: url('/assets/fonts/archive-mono-pro.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/archive-mono-pro.woff2') format('woff2'),
    url('/assets/fonts/archive-mono-pro.woff') format('woff'),
    url('/assets/fonts/archive-mono-pro.ttf') format('truetype');
  font-display: swap;
}

$archive: 'Archive', 'Times New Roman', times, serif;
$mono: 'Archive Mono', monospace;

$serif: $archive;

.text-mono {
  font-family: $mono;
}

.text-serif {
  font-family: $serif;
}

// $sans-serif: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
